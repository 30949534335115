import { Injectable } from '@angular/core';
import { translations } from '@shared/utils/translations';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class CustomMessageService {
    constructor(private messageService: MessageService) {}

    success(message?: string, life?: number) {
        this.messageService.add({
            severity: 'success',
            summary: message ?? translations.global.success,
            life: life ?? 5000,
        });
    }

    info(message?: string, life?: number) {
        this.messageService.add({
            severity: 'info',
            summary: message ?? translations.global.information,
            life: life ?? 5000,
        });
    }

    error(message?: string, life?: number) {
        this.messageService.add({
            severity: 'error',
            summary: message ?? translations.global.success,
            life: life ?? 5000,
        });
    }

    clear() {
        this.messageService.clear();
    }
}
